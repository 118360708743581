<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-mingle">
      <h2 class="onboarding-content-title">Mingle much?</h2>
      <p>Pick your people level.</p>
      <component
        :is="activeItem.component"
        style="justify-self: center; padding: 0 4rem"
        height="200"
        width="100%"
      />
      <div
        style="
          position: relative;
          display: flex;
          align-items: center;
          z-index: 0;
        "
      >
        <div class="slider-ticks">
          <div
            v-for="num in max"
            :key="num"
            :data-hide="num === activeId"
          ></div>
        </div>
        <form-input
          v-model="activeId"
          type="range"
          name="mingle-slider"
          :min="min"
          :max="max"
        />
      </div>
      <p style="padding: 0 4rem">{{ activeItem.text }}</p>
    </div>
    <XButton type="large" style="margin-top: auto" @click="next">Next</XButton>
  </div>
</template>

<script>
import IconMingle1 from '../../components-rf/icons/onboarding/IconMingle1.vue'
import IconMingle2 from '../../components-rf/icons/onboarding/IconMingle2.vue'
import IconMingle3 from '../../components-rf/icons/onboarding/IconMingle3.vue'
import IconMingle4 from '../../components-rf/icons/onboarding/IconMingle4.vue'
import IconMingle5 from '../../components-rf/icons/onboarding/IconMingle5.vue'
import XButton from '../../components/common/global/XButton.vue'

const list = [
  {
    id: 1,
    component: IconMingle1,
    text: 'Animals instead, please.',
  },
  {
    id: 2,
    component: IconMingle2,
    text: 'Away from the crowds.',
  },
  {
    id: 3,
    component: IconMingle3,
    text: 'A little chit chat here or there.',
  },
  {
    id: 4,
    component: IconMingle4,
    text: 'Being among other travelers.',
  },
  {
    id: 5,
    component: IconMingle5,
    text: 'Lots of locals. Lots of stories.',
  },
]

export default {
  components: { XButton },
  name: 'OnboardingMingle',
  created() {
    const idList = list.map(el => el.id)
    this.min = this.activeId = Math.min.apply(Math, idList)
    this.max = Math.max.apply(Math, idList)
  },
  data() {
    return {
      activeId: 0,
      list,
      min: 0,
      max: 0,
    }
  },
  computed: {
    activeItem() {
      return this.list.find(({ id }) => id === this.activeId)
    },
  },
  methods: {
    next() {
      this.$router.push('/onboarding/connectivity')
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-mingle {
  display: grid;
  gap: 1rem;
}
.slider-ticks {
  position: absolute;
  z-index: -1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  & > div {
    height: 10px;
    width: 2px;
    background-color: #000;
    position: relative;
    &[data-hide='true'] {
      opacity: 0;
    }
  }
}
</style>
